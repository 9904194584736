<ng-container *transloco="let t; read: 'notifications'">
  <div class="flex w-full gap-4 px-4 py-3">
    <cca-icon
      [icon]="getNotificationIconType()"
      class="box-content block h-6 w-6 rounded-full p-2 text-2xl leading-none"
      [ngClass]="getNotificationIconColor()"
      [class.unread]="!notification.isRead"
    ></cca-icon>
    <div
      (click)="onNotificationClick()"
      class="flex cursor-pointer flex-col gap-1"
    >
      <h3 class="text-base font-bold">
        {{ notification.title }}
      </h3>
      <p>
        {{ getText() | transloco }}
      </p>
      <span class="text-xs text-neutral-caption">
        {{ notification.sentAt | ccaDate: 'f' }}
      </span>
      @if ((getReferenceNumber() && getEntityType()) || getTaskId()) {
        <a class="cursor-pointer py-1.5 font-bold underline text-brand-default">
          {{ t('link') }}
        </a>
      }
    </div>
    <div class="text-end">
      <mat-checkbox
        [matTooltip]="
          !notification.isRead ? t('markAsRead') : t('markAsUnread')
        "
        [checked]="notification.isRead"
        (change)="
          !notification.isRead
            ? notificationsStore.markAsRead(notification)
            : notificationsStore.markAsUnread(notification)
        "
      ></mat-checkbox>
    </div>
  </div>
</ng-container>
